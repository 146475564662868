import React from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";


export default function Store() {

    return (
        <div className="d-flex">
            <div>
                <Sidebar />
            </div>
            
        </div>
    );
}